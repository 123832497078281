import { QUOTE_TYPE_DUAL } from 'redux/modules/Energy/Quote/service/postGenerateEnergyQuotes';
import {
  ENERGY_USE_MEDIUM_VALUE,
  GAS_USE_MEDIUM_VALUE,
} from 'redux/modules/Energy/ProductSelectionForm/constants';

export const QUOTE_TYPE = QUOTE_TYPE_DUAL;
export const ELECTRICITY_CONSUMPTION = ENERGY_USE_MEDIUM_VALUE;
export const GAS_CONSUMPTION = GAS_USE_MEDIUM_VALUE;
export const PAYMENT_PLAN = 'budget';
export const TARIFF_TYPE = 'fixed';
