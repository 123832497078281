import {
  delay,
  call,
  put,
  select,
  take,
  takeLatest,
  fork,
} from 'redux-saga/effects';
import * as actions from './actions';
import * as filters from './filters';
import * as PersistenceEngine from './engine.local';
import Lock from './lock';
import {
  DEBOUNCE_TIME,
  SERVER_LOAD_REQUEST,
  SERVER_SAVE_REQUEST,
} from './constants';
import { ACTION_TYPE as RemoteSessionActionType } from '../RemoteSession/v2/constants';
import { RemoteSessionApi } from '../RemoteSession/v2/api';
import serverLoad from './sagas/serverLoad';
import serverSave from './sagas/serverSave';

export function* combinedSagas() {
  yield takeLatest(SERVER_LOAD_REQUEST, serverLoad);
  yield takeLatest(SERVER_SAVE_REQUEST, serverSave);
  yield fork(persistenceSaga);
}

/**
 * persistenceSaga - handles store saving
 */
export function* persistenceSaga() {
  let debounceLock = new Lock(debounceSave);

  while (true) {
    const action = yield take();

    const isPeerConnected = yield select(RemoteSessionApi.isPeerConnected);
    if (
      isPeerConnected &&
      action.type.startsWith('REMOTE_SESSION') &&
      action.type !== RemoteSessionActionType.END_SESSION
    ) {
      continue; // we want to ignore the persistent session action updates
    }

    const type = filters.getPersistenceType(action.type);

    if (!type) {
      continue;
    }

    // each persistent action cancels the debounce timer
    yield debounceLock.cancel();

    if (type === filters.PersistenceType.IMMEDIATE) {
      yield fork(save); // save immediately
    } else if (type === filters.PersistenceType.DEBOUNCE) {
      // a new debounce timer is created
      yield debounceLock.execute();
    }
  }
}

/**
 * save store handler - immediate effect
 */
function* save() {
  // select the store, or part of it...
  const state = (yield select()).toJS();

  // save state in the server, if possible
  yield put(actions.serverSave.request(state));

  // save state locally
  try {
    yield call(PersistenceEngine.save, state);
    yield put(actions.serverSave.success());
  } catch (e) {
    yield put(actions.serverSave.failure());
  }
}

/**
 * save store handler - with debounce
 */
function* debounceSave() {
  try {
    yield delay(DEBOUNCE_TIME);
    const state = yield select();
    yield call(save, state);
  } catch (e) {
    // empty exception handler because the cancel effect throws an exception
  }
}

/**
 * signals to the UI about unsaved changes handler
 */
export function* signalPersistenceState() {
  yield put(actions.signalUnsavedChanges());
}
