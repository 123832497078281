import { select, call, put } from 'redux-saga/effects';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import putApplicationProductsMobile from 'redux/modules/Mobile/service/putApplicationProductsMobile';
import getApplicationMobileTariffs from 'redux/modules/Mobile/service/getApplicationMobileTariffs';
import { getSelectedMobileProducts, getSelectedTariff, getStore } from './api';
import { mapMobile } from 'redux/modules/Mobile/saga';
import {
  addMobileProduct,
  getTariffsSuccess,
  resetStore,
  setSelectedTariffLabel,
} from './actions';
import { TARIFF, NUMBER_OF_SIMS } from './defaults';
import { preselectFailure } from '../actions';
import { preselectSuccess } from 'redux/modules/Mobile/actions';

export function* selectProducts({
  tariff = TARIFF,
  numberOfSims = NUMBER_OF_SIMS,
}) {
  try {
    const { data } = yield call(getApplicationMobileTariffs);
    const hasPreselectedData = data.find(
      ({ label }) => label.toLowerCase() === tariff.toLowerCase()
    );

    if (!hasPreselectedData) {
      throw new Error('Preselected mobile tariff is invalid');
    }
    yield put(getTariffsSuccess(data));
    yield call(addMobileProducts, tariff, numberOfSims);
  } catch (error) {
    Sentry.log(error, ERRORS.GET_MOBILE_TARIFFS);
    return yield put(preselectFailure(error));
  }
}

function* addMobileProducts(tariff, numberOfSims) {
  for (let i = 0; i < numberOfSims; i++) {
    yield put(addMobileProduct());
  }
  yield put(setSelectedTariffLabel(tariff));
}

export function* putProducts() {
  try {
    const selected = yield select(getSelectedMobileProducts);
    const tariff = yield select(getSelectedTariff);
    yield call(
      putApplicationProductsMobile,
      selected.map(mapMobile.bind(null, tariff))
    );
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_MOBILE_PRODUCTS);

    return yield preselectFailure(new Error('Error adding mobile products'));
  }
}

export function* migrateStore() {
  const preselectState = yield select(getStore);
  yield put(preselectSuccess(preselectState));
  yield put(resetStore());
}
