import { genericAuthorizedRequest, METHOD } from 'redux/utils/request';
import { API_URL } from 'config';

export const getState = async (partnerId, code, token) => {
  const result = await genericAuthorizedRequest(
    METHOD.GET,
    `${API_URL}/partner/${partnerId}/state/${code}`,
    token
  );

  return result.data;
};

export const saveState = async (partnerId, code, state, token) => {
  const result = await genericAuthorizedRequest(
    METHOD.PUT,
    `${API_URL}/partner/${partnerId}/state/${code}`,
    token,
    state
  );

  return result.data;
};
