import * as types from './constants';

export function signalUnsavedChanges() {
  return {
    type: types.UNSAVED_CHANGES,
  };
}

export function signalSavedChanges() {
  return {
    type: types.SAVED_CHANGES,
  };
}

export const serverSave = {
  request: (state) => {
    return { type: types.SERVER_SAVE_REQUEST, payload: state };
  },
  success: () => {
    return { type: types.SERVER_SAVE_SUCCESS };
  },
  failure: ({ error }) => {
    return { type: types.SERVER_SAVE_FAILURE, error };
  },
};

export const serverLoad = {
  request: (code) => {
    return { type: types.SERVER_LOAD_REQUEST, code };
  },
  success: (state) => {
    return { type: types.SERVER_LOAD_SUCCESS, payload: state };
  },
  failure: ({ error }) => {
    return { type: types.SERVER_LOAD_FAILURE, error };
  },
};
