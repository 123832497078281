import * as types from './types';

export const resetStore = () => ({
  type: types.RESET_STORE,
});

export const getQuotesRequestSucess = (quotes, quoteType, tariffType) => ({
  type: types.GET_QUOTES_REQUEST_SUCCESS,
  quotes,
  quoteType,
  tariffType,
});
