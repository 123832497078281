import * as types from './types';

export const preselectFailure = (error) => ({
  type: types.PRESELECT_FAILURE,
  error,
});

export const preselectSuccess = () => ({
  type: types.PRESELECT_SUCCESS,
});

export const preselectFailureCleanUp = () => ({
  type: types.PRESELECT_FAILURE_CLEAR_UP,
});
