import {
  TARIFF_FTTP_100,
  TARIFF_ULTRA,
  DEFAULT_NUMBER_OF_EEROS,
  NO_CALL_PACKAGE,
  TECHNOLOGY_FTTP,
  TARIFF_FIBRE_40,
} from 'redux/modules/Broadband/constants';
import { UW_HUB } from './constants';

export const PREFERRED_TARIFF = TECHNOLOGY_FTTP;
export const FIBRE_TARIFF = TARIFF_FTTP_100;
export const ULTRA_TARIFF = TARIFF_ULTRA;
export const SOGEA_TARIFF = TARIFF_FIBRE_40;
export const ROUTER = UW_HUB;
export const EEROS = DEFAULT_NUMBER_OF_EEROS;
export const CALL_PACKAGE = NO_CALL_PACKAGE.name;
