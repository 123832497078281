import {
  NO_CALL_PACKAGE,
  PACKAGE_NAME_EERO,
  PACKAGE_NAME_NO_ROUTER,
} from '../../Broadband/constants';
import { OFF_PEAK_SAVER, PEAK_SAVER, UW_HUB } from '../Broadband/constants';
import {
  BROADBAND_PREFERRED_TARIFF,
  BROADBAND_COPPER_TARIFF,
  BROADBAND_FIBRE_TARIFF,
  BROADBAND_SOGEA_TARIFF,
  BROADBAND_CALL_PACKAGE,
  BROADBAND_ROUTER,
  BROADBAND_EEROS,
  ENERGY_TARIFF,
  MOBILE_SIMS,
  MOBILE_TARIFF,
  ENERGY_TARIFF_TYPE,
} from './constants';

export const schema = {
  [ENERGY_TARIFF]: {
    validValues: ['dual', 'electricity', 'gas'],
    validate(value) {
      return this.validValues.includes(value);
    },
  },
  [ENERGY_TARIFF_TYPE]: {
    validValues: ['fixed', 'variable'],
    validate(value) {
      return this.validValues.includes(value);
    },
  },
  [BROADBAND_PREFERRED_TARIFF]: {
    validValues: ['FTTC', 'FTTP'],
    validate(value) {
      return this.validValues.includes(value);
    },
  },
  [BROADBAND_FIBRE_TARIFF]: {
    validValues: ['fullfibre100', 'fullfibre500'],
    validate(value) {
      return this.validValues.includes(value);
    },
  },
  [BROADBAND_COPPER_TARIFF]: {
    validValues: ['ultra', 'ultraplus'],
    validate(value) {
      return this.validValues.includes(value);
    },
  },
  [BROADBAND_SOGEA_TARIFF]: {
    validValues: ['fibre40', 'fibre80'],
    validate(value) {
      return this.validValues.includes(value);
    },
  },
  [BROADBAND_ROUTER]: {
    validValues: ['uw_hub', 'none', 'whole_home'],
    validate(value) {
      return this.validValues.includes(value);
    },
  },
  [BROADBAND_EEROS]: {
    maxValue: 5,
    minValue: 2,
    validate(value) {
      return (
        this.minValue <= parseInt(value, 10) &&
        parseInt(value, 10) <= this.maxValue
      );
    },
  },
  [BROADBAND_CALL_PACKAGE]: {
    validValues: ['none', 'peak_saver', 'off_peak_saver'],
    validate(value) {
      return this.validValues.includes(value);
    },
  },
  [MOBILE_TARIFF]: {
    validValues: ['UNLIMITED_PLUS', 'ESSENTIAL', 'UNLIMITED', 'VALUE'],
    validate(value) {
      return this.validValues.includes(value);
    },
  },
  [MOBILE_SIMS]: {
    maxValue: 4,
    minValue: 1,
    validate(value) {
      return (
        this.minValue <= parseInt(value, 10) &&
        parseInt(value, 10) <= this.maxValue
      );
    },
  },
};

export const validateParameters = (params) =>
  Object.entries(params)
    .filter(([key]) => schema[key])
    .every(([key, value]) => schema[key].validate(value));

export const mapParamToRouter = (param) => {
  switch (param) {
    case 'none':
      return PACKAGE_NAME_NO_ROUTER;
    case 'uw_hub':
      return UW_HUB;
    case 'whole_home':
      return PACKAGE_NAME_EERO;
  }
};

export const mapParamToCallPackage = (param) => {
  switch (param) {
    case 'none':
      return NO_CALL_PACKAGE.name;
    case 'peak_saver':
      return PEAK_SAVER;
    case 'off_peak_saver':
      return OFF_PEAK_SAVER;
  }
};
