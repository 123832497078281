/* eslint-disable no-case-declarations */
/* eslint-disable no-shadow */

import Immutable from 'immutable';

import { SERVER_SAVE_REQUEST, TTL } from './constants';

const initialState = Immutable.fromJS({
  timestamp: null,
  TTL: null,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case SERVER_SAVE_REQUEST:
      return state.merge({
        TTL,
        timestamp: Date.now(),
      });

    default:
      return state;
  }
}
