import { STORE_NAME as PRESELECT_STORE_NAME } from '../constants';
import { STORE_NAME } from './constants';

export const getMobileParams = (state) => {
  return state.getIn([PRESELECT_STORE_NAME, STORE_NAME, 'mobile']).toJS();
};

export const getBroadbandParams = (state) => {
  return state.getIn([PRESELECT_STORE_NAME, STORE_NAME, 'broadband']).toJS();
};

export const getEnergyParams = (state) => {
  return state.getIn([PRESELECT_STORE_NAME, STORE_NAME, 'energy']).toJS();
};

export const getIsPreselectJourney = (state) => {
  return state.getIn([PRESELECT_STORE_NAME, STORE_NAME, 'isPreselectJourney']);
};
