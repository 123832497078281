import {
  call,
  put,
  takeLatest,
  race,
  take,
  select,
  fork,
} from 'redux-saga/effects';

import { JOURNEY_START_PRESELECT } from 'redux/modules/EntryOrganic/types';
import {
  preselectFailure,
  preselectFailureCleanUp,
  preselectSuccess,
} from './actions';
import {
  selectProducts as selectBroadbandProducts,
  putProducts as putBroadbandProducts,
  migrateStore as migrateBroadbandStore,
} from './Broadband/saga';
import {
  selectProducts as selectMobileProducts,
  putProducts as putMobileProducts,
  migrateStore as migrateMobileStore,
} from './Mobile/saga';
import {
  setUpRequests as energySetUp,
  selectProducts as selectEnergyProducts,
  putProducts as putEnergyProducts,
  migrateStore as migrateEnergyStore,
} from './Energy/saga';
import { setServices } from 'redux/modules/ServiceSelection/actions';
import { PRESELECT_FAILURE } from './types';
import { STORE_PRESELECT_PARAMETERS } from './Parameters/types';
import { handleStoreParameters } from './Parameters/saga';
import {
  getBroadbandParams,
  getEnergyParams,
  getMobileParams,
} from './Parameters/api';
import {
  BROADBAND_PREFERRED_TARIFF,
  BROADBAND_CALL_PACKAGE,
  BROADBAND_COPPER_TARIFF,
  BROADBAND_EEROS,
  BROADBAND_FIBRE_TARIFF,
  BROADBAND_ROUTER,
  ENERGY_TARIFF,
  ENERGY_TARIFF_TYPE,
  MOBILE_SIMS,
  MOBILE_TARIFF,
  BROADBAND_SOGEA_TARIFF,
} from './Parameters/constants';
import { startAgain } from 'redux/modules/App/actions';
import {
  getAddressId,
  getPropertyOccupancy,
} from 'redux/modules/EntryOrganic/api';
import postApplicationPropertyAddress from 'redux/modules/EntryForm/service/postApplicationPropertyAddress';
import { handleCreateOrganicApplication } from 'redux/modules/App/saga';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import { resetPreselectParameters } from './Parameters/actions';
import { mapParamToRouter, mapParamToCallPackage } from './Parameters/schema';
import { ApplicationApi } from 'redux/modules/App/api';
import { updatedSelectedServices } from 'redux/modules/ServiceSelection/service/updateSelectedServices';
import { handleGetProposition } from '../Bundles/saga';

function* setUp(selectedServices) {
  try {
    yield call(handleGetProposition);
    // Gas and electricity supply requests need to be sent before the
    // requested-services request as they both reset values in the backend application.
    if (selectedServices.includes('energy')) {
      const energyParams = yield select(getEnergyParams);
      yield call(energySetUp, { quoteType: energyParams[ENERGY_TARIFF] });
    }
    const [applicationId, token] = yield select((state) => [
      ApplicationApi.getCurrentApplication(state),
      ApplicationApi.getToken(state),
    ]);
    yield call(updatedSelectedServices, applicationId, token, selectedServices);
    yield put(setServices(selectedServices));
  } catch (error) {
    yield put(preselectFailure(error));
  }
}

function* selectServices(selectedServices) {
  if (selectedServices.includes('broadband')) {
    const bbParams = yield select(getBroadbandParams);
    const bbRequest = {
      preferredTariff: bbParams[BROADBAND_PREFERRED_TARIFF],
      fibreTariff: bbParams[BROADBAND_FIBRE_TARIFF],
      ultraTariff: bbParams[BROADBAND_COPPER_TARIFF],
      sogeaTariff: bbParams[BROADBAND_SOGEA_TARIFF],
      router: mapParamToRouter(bbParams[BROADBAND_ROUTER]),
      eeros: bbParams[BROADBAND_EEROS],
      callPackage: mapParamToCallPackage(bbParams[BROADBAND_CALL_PACKAGE]),
    };
    yield fork(selectBroadbandProducts, bbRequest);
  }
  if (selectedServices.includes('mobile')) {
    const mobileParams = yield select(getMobileParams);
    const mobileRequest = {
      tariff: mobileParams[MOBILE_TARIFF],
      numberOfSims: mobileParams[MOBILE_SIMS],
    };
    yield fork(selectMobileProducts, mobileRequest);
  }
  if (selectedServices.includes('energy')) {
    const energyParams = yield select(getEnergyParams);
    const energyRequest = {
      quoteType: energyParams[ENERGY_TARIFF],
      tariffType: energyParams[ENERGY_TARIFF_TYPE],
      selectedServices,
    };
    yield fork(selectEnergyProducts, energyRequest);
  }
}

function* putServices(selectedServices) {
  if (selectedServices.includes('broadband')) {
    yield fork(putBroadbandProducts);
  }
  if (selectedServices.includes('mobile')) {
    yield fork(putMobileProducts);
  }
  if (selectedServices.includes('energy')) {
    yield fork(putEnergyProducts);
  }
}

function* handleSelectSuccess(selectedServices) {
  if (selectedServices.includes('broadband')) {
    yield fork(migrateBroadbandStore);
  }
  if (selectedServices.includes('mobile')) {
    yield fork(migrateMobileStore);
  }
  if (selectedServices.includes('energy')) {
    yield fork(migrateEnergyStore);
  }
}

function* racer() {
  const selectedServices = [];
  const bbParams = yield select(getBroadbandParams);
  if (bbParams[BROADBAND_COPPER_TARIFF] || bbParams[BROADBAND_FIBRE_TARIFF]) {
    selectedServices.push('broadband');
  }
  const mobileParams = yield select(getMobileParams);
  if (mobileParams[MOBILE_TARIFF]) {
    selectedServices.push('mobile');
  }
  const energyParams = yield select(getEnergyParams);
  if (energyParams[ENERGY_TARIFF]) {
    selectedServices.push('energy');
  }

  const [, cancel] = yield race([
    call(preselectServices, selectedServices),
    take(PRESELECT_FAILURE),
  ]);
  if (!cancel) {
    yield put(preselectSuccess());
  } else {
    yield call(handleFailure);
  }
}

function* preselectServices(selectedServices) {
  yield call(setUp, selectedServices);
  yield call(selectServices, selectedServices);
  yield call(putServices, selectedServices);
  yield call(handleSelectSuccess, selectedServices);
}

function* handleFailure() {
  try {
    yield put(resetPreselectParameters());
    yield call(handleCreateOrganicApplication, {});
    const address = yield select(getAddressId);
    const occupancy = yield select(getPropertyOccupancy);
    yield call(postApplicationPropertyAddress, { uuid: address }, occupancy);
    yield put(preselectFailureCleanUp());
  } catch (error) {
    yield put(startAgain());
    yield put(
      openErrorGlobalDialog(
        'Sorry there was an error creating your application, please try again'
      )
    );
  }
}

export function* combinedSagas() {
  yield takeLatest(JOURNEY_START_PRESELECT, racer);
  yield takeLatest(STORE_PRESELECT_PARAMETERS, handleStoreParameters);
}
