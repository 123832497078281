import { call, put, select } from 'redux-saga/effects';
import * as actions from '../actions';
import * as PersistenceEngine from '../engine.local';
import { PartnerLoginApi } from '../../PartnerLogin/api';
import { openErrorGlobalDialog } from '../../GlobalDialog/actions';
import * as service from '../service';
import get from 'lodash/get';
import history from 'components/root/browserHistory';
import { saveRouting } from 'redux/utils/localStorageHelpers';

export default function* serverLoad({ code }) {
  try {
    const [token, partnerId] = yield select((state) => [
      PartnerLoginApi.getPartnerToken(state),
      PartnerLoginApi.getPartnerId(state),
    ]);
    if (!code || !partnerId || !token) return;

    const res = yield call(service.getState, partnerId, code, token);
    if (!res.state || !res.routing || !res.path) return;

    const currentState = (yield select()).toJS();
    const state = { ...currentState, ...res.state };
    yield put(actions.serverLoad.success(state));
    yield call(PersistenceEngine.save, state);
    saveRouting(res.routing);
    history.push(res.path);
  } catch (e) {
    const message = get(e, 'response.data.message', e.message);
    yield put(actions.serverSave.failure({ error: message }));
    yield put(openErrorGlobalDialog(message));
  }
}
