import * as types from './types';

export const getBroadbandRequestSuccess = (
  tariffs,
  hasGoldDiscount,
  callPackages
) => ({
  type: types.GET_BROADBAND_REQUEST_SUCCESS,
  tariffs,
  hasGoldDiscount,
  callPackages,
});

export const setTariff = (tariff) => ({
  type: types.SET_TARIFF,
  tariff,
});

export const setRouter = (router) => ({
  type: types.SET_ROUTER,
  router,
});

export const setTechnology = (technology) => ({
  type: types.SET_TECHNOLOGY,
  technology,
});

export const setEeros = (numOfEeros) => ({
  type: types.SET_EEROS,
  numOfEeros,
});

export const setSelectedCallPackage = (callPackageId) => ({
  type: types.SET_SELECTED_CALL_PACKAGE,
  callPackageId,
});

export const resetStore = () => ({
  type: types.RESET_STORE,
});
