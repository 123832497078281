import { select, call, put, all } from 'redux-saga/effects';

import { getQuoteIds, getStore } from './api';
import { getQuotesRequestSucess, resetStore } from './actions';
import {
  ELECTRICITY_CONSUMPTION,
  GAS_CONSUMPTION,
  PAYMENT_PLAN,
  QUOTE_TYPE,
  TARIFF_TYPE,
} from './defaults';

import { preselectFailure } from 'redux/modules/Preselect/actions';
import { preselectSuccess as quotePreselectSuccess } from 'redux/modules/Energy/Quote/actions';
import { preselectSuccess as formPreselectSuccess } from 'redux/modules/Energy/ProductSelectionForm/actions';
import {
  putApplicationPropertySupplyElectricity,
  putApplicationPropertySupplyGas,
} from 'redux/modules/Energy/ProductSelectionForm/service/putApplicationPropertyMeter';
import generateEnergyQuotes, {
  QUOTE_TYPE_DUAL,
} from 'redux/modules/Energy/Quote/service/postGenerateEnergyQuotes';
import acceptEnergyQuotes from 'redux/modules/Energy/Quote/service/putAcceptEnergyQuotes';
import { mapCurrentQuote } from 'redux/modules/Energy/Quote/presentation';

export function* setUpRequests({
  gasConsumption = GAS_CONSUMPTION,
  electricityConsumption = ELECTRICITY_CONSUMPTION,
  nightConsumption,
  quoteType = QUOTE_TYPE,
  prepayment,
}) {
  try {
    const products =
      quoteType === QUOTE_TYPE_DUAL ? ['electricity', 'gas'] : [quoteType];
    const requests = [];
    if (products.includes('electricity')) {
      requests.push(
        call(
          putApplicationPropertySupplyElectricity,
          electricityConsumption,
          nightConsumption,
          prepayment,
          false
        )
      );
    }
    if (products.includes('gas')) {
      requests.push(call(putApplicationPropertySupplyGas, gasConsumption));
    }
    yield all(requests);
  } catch (error) {
    yield put(preselectFailure(error));
  }
}

export function* selectProducts({
  quoteType = QUOTE_TYPE,
  paymentPlan = PAYMENT_PLAN,
  tariffType = TARIFF_TYPE,
  selectedServices,
}) {
  try {
    const products =
      quoteType === QUOTE_TYPE_DUAL ? ['electricity', 'gas'] : [quoteType];
    const { data } = yield call(
      generateEnergyQuotes,
      quoteType,
      paymentPlan,
      selectedServices,
      tariffType
    );
    const tariffs = data.current;

    const hasPreselectedData = products.every((product) =>
      tariffs.quotes.find(
        ({ type }) => type.toLowerCase() === product.toLowerCase()
      )
    );

    if (!hasPreselectedData) {
      throw new Error('Preselected energy tariff is invalid');
    }
    yield put(
      getQuotesRequestSucess(
        mapCurrentQuote(tariffs),
        quoteType,
        tariffType.toUpperCase()
      )
    );
  } catch (error) {
    yield put(preselectFailure(error));
  }
}

export function* putProducts() {
  try {
    const quoteIds = yield select(getQuoteIds);
    yield call(acceptEnergyQuotes, quoteIds);
  } catch (error) {
    yield put(preselectFailure(error));
  }
}

export function* migrateStore() {
  const { form, quote } = yield select(getStore);
  yield put(quotePreselectSuccess(quote));
  yield put(formPreselectSuccess(form));
  yield put(resetStore());
}
