import { takeLatest, select, put } from 'redux-saga/effects';
import { STORE_PRESELECT_PARAMETERS } from './types';
import { ApplicationApi } from 'redux/modules/App/api';
import { schema, validateParameters } from './schema';
import { preselectFailure } from '../actions';
import { setPreselectParameters } from './actions';
import { BROADBAND_PREFIX, ENERGY_PREFIX, MOBILE_PREFIX } from './constants';

export function* handleStoreParameters() {
  const params = yield select(ApplicationApi.getPageParams);

  const valid = validateParameters(params);
  if (!valid) {
    return yield put(
      preselectFailure('Could not select products: Incorrect options provided')
    );
  }

  const mappedParams = Object.entries(params).reduce(
    (acc, [key, value]) => {
      if (!schema[key]) {
        return acc;
      }

      switch (true) {
        case key.startsWith(MOBILE_PREFIX):
          acc.mobile[key] = value;
          break;
        case key.startsWith(BROADBAND_PREFIX):
          acc.broadband[key] = value;
          break;
        case key.startsWith(ENERGY_PREFIX):
          acc.energy[key] = value;
          break;
      }
      return acc;
    },
    {
      mobile: {},
      broadband: {},
      energy: {},
    }
  );
  return yield put(setPreselectParameters(mappedParams));
}

export function* combinedSagas() {
  yield takeLatest(STORE_PRESELECT_PARAMETERS, handleStoreParameters);
}
