import { STORE_NAME as PRESELECT_STORE_NAME } from 'redux/modules/Preselect/constants';
import { STORE_NAME } from './constants';

export const getSelectedMobileProducts = (state) =>
  state.getIn([PRESELECT_STORE_NAME, STORE_NAME, 'selectedProducts']).toJS();

export const getSelectedTariff = (state) => {
  const selectedTariffLabel = state.getIn([
    PRESELECT_STORE_NAME,
    STORE_NAME,
    'selectedTariffLabel',
  ]);
  return state
    .getIn([PRESELECT_STORE_NAME, STORE_NAME, 'tariffs', 'nodes'])
    ?.toJS()
    ?.find((t) => t.label === selectedTariffLabel);
};

export const getStore = (state) => {
  return state.getIn([PRESELECT_STORE_NAME, STORE_NAME])?.toJS();
};
