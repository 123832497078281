import * as types from './types';

export const getTariffsSuccess = (tariffs) => ({
  type: types.GET_TARIFFS_SUCCESS,
  tariffs,
});

export const addMobileProduct = () => ({
  type: types.ADD_MOBILE_PRODUCT,
});

export const setSelectedTariffLabel = (label) => ({
  type: types.SET_SELECTED_TARIFF_LABEL,
  label,
});

export const resetStore = () => ({
  type: types.RESET_STORE,
});
