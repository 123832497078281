import { includes } from 'lodash';
import * as types from './constants';
import {
  ANALYTICS_SNOWPLOW_SET_READY,
  ANALYTICS_OPTLY_SET_READY,
} from 'redux/modules/Analytics/constants';
import { STORE_NAME as PartnerLogin } from 'redux/modules/PartnerLogin/constants';
import { STORE_NAME as Persistence } from 'redux/modules/Persistence/constants';
import { STORE_NAME as RemoteSession } from 'redux/modules/RemoteSession/v2/constants';
import { STORE_NAME as FeatureFlags } from 'redux/modules/FeatureFlags/constants';
import { STORE_NAME as Notifications } from 'redux/modules/Notifications/constants';
import { STORE_NAME as GlobalDialog } from 'redux/modules/GlobalDialog/constants';
import { STORE_NAME as PaymentForm } from 'redux/modules/PaymentForm/constants';

export const PersistenceType = {
  IMMEDIATE: 'IMMEDIATE',
  DEBOUNCE: 'DEBOUNCE',
};

// by default accept all actions, prevents infinite loop
const blackList = [
  'INIT',
  types.SERVER_SAVE_REQUEST,
  types.SERVER_SAVE_SUCCESS,
  types.SERVER_SAVE_FAILURE,

  types.SERVER_LOAD_REQUEST,
  types.SERVER_LOAD_SUCCESS,
  types.SERVER_LOAD_FAILURE,
  ANALYTICS_OPTLY_SET_READY,
  ANALYTICS_SNOWPLOW_SET_READY,
];

// alternatively accept only whitelisted actions, requires a change in getPersistenceType method
// const whitelist = [];

// by default all actions are debounced, we can override this behaviour here:
const typeOverride = {
  // [types.SOME_ACTION_NAME] : PersistenceType.IMMEDIATE,
};

/**
 * getPersistenceType returns a persistance type
 * returns 1 of 3 values: null or PersistenceType.DEBOUNCE or PersistenceType.IMMEDIATE
 */
export function getPersistenceType(type) {
  if (includes(blackList, type)) {
    return null;
  }
  // if (!_.includes(whitelist, type)) {
  // 	return null;
  // }

  return typeOverride[type] || PersistenceType.DEBOUNCE;
}

// Some state data is either sensitive or would conflict in case of application
// restoration. This deny list specifies what should be filtered when saving to
// server.
const SERVER_STATE_DENY_LIST = [
  // Same data filtered by remote filter
  Persistence,
  PartnerLogin,
  RemoteSession,
  FeatureFlags,
  Notifications,
  // Extra data to be cleaned before sending to server
  GlobalDialog,
  PaymentForm,
];

// Filters the state which will be sent to the server
export const stateToServer = (state) =>
  Object.keys(state).reduce(
    (acc, k) =>
      SERVER_STATE_DENY_LIST.includes(k) ? acc : { ...acc, [k]: state[k] },
    {}
  );

// Certains paths should not trigger server save due to sensitive data or
// business decisions. At this point, it will only save up to the order
// summary page (one before the "About You" page)
export const isPathSaveEnabled = (path) =>
  ![
    '/order/aboutyou',
    '/order/confirmation',
    '/checkout/thankyou',
    '/order/billprotector',
    '/checkout/payment',
  ].includes(path);
