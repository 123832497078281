import { get } from 'lodash';
import { createRequestStateObject } from '../../utils/isRequestInProgress';
import { REQUEST_STATUS_STORE_KEY } from '../../../constants/requestStatus';

const STORAGE_KEY = 'persistence';

const getLastKnownRoute = (stateObj) => {
  return get(stateObj.routing, 'locationBeforeTransitions.pathname', '/');
};

const removeRequestStates = (state) => {
  Object.keys(state).forEach((storeName) => {
    if (state[storeName] != null && state[storeName] != undefined) {
      if (get(state[storeName], REQUEST_STATUS_STORE_KEY, false)) {
        state[storeName] = {
          ...state[storeName],
          ...createRequestStateObject(
            Object.keys(state[storeName][REQUEST_STATUS_STORE_KEY])
          ),
        };
      }
    }
  });

  return state;
};

export function save(state) {
  const stateObj = removeRequestStates(state);

  stateObj._lastKnownRoute = getLastKnownRoute(stateObj);
  // do not save the current routing information
  stateObj.routing = undefined;

  if (window.sessionStorage) {
    window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(stateObj));
  }
  return Promise.resolve({});
}

export function clear() {
  if (window.sessionStorage) {
    window.sessionStorage.removeItem(STORAGE_KEY);
  }
  return Promise.resolve({});
}

export function load() {
  let state = null;

  if (window.sessionStorage) {
    state = window.sessionStorage.getItem(STORAGE_KEY);
  }

  return Promise.resolve(state ? JSON.parse(state) : null);
}
