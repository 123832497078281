import { combineReducers } from 'redux-immutablejs';

import { STORE_NAME as Mobile } from './Mobile/constants';
import MobilePreselectReducer from './Mobile/reducer';
import { STORE_NAME as Broadband } from './Broadband/constants';
import BroadbandPreselectReducer from './Broadband/reducer';
import { STORE_NAME as Parameters } from './Parameters/constants';
import ParameterReducer from './Parameters/reducer';
import { STORE_NAME as Energy } from './Energy/constants';
import EnergyPreselectReducer from './Energy/reducer';

export default combineReducers({
  [Mobile]: MobilePreselectReducer,
  [Broadband]: BroadbandPreselectReducer,
  [Parameters]: ParameterReducer,
  [Energy]: EnergyPreselectReducer,
});
