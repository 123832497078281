import { call, put, select } from 'redux-saga/effects';
import * as actions from '../actions';
import * as filters from '../filters';
import { ApplicationApi } from '../../App/api';
import { PartnerLoginApi } from '../../PartnerLogin/api';
import { FeatureFlagsApi } from '../../FeatureFlags/api';
import { RemoteSessionApi } from '../../RemoteSession/v2/api';
import { ActorType } from '../../RemoteSession/v2/actor';
import * as service from '../service';
import { getRouting } from 'redux/utils/localStorageHelpers';
import { FLAGS } from '../../FeatureFlags/constants';

export default function* serverSave({ payload }) {
  try {
    const [
      token,
      partnerId,
      applicationId,
      actor,
      isRestoreEnabled,
    ] = yield select((state) => [
      PartnerLoginApi.getPartnerToken(state),
      PartnerLoginApi.getPartnerId(state),
      ApplicationApi.getCurrentApplication(state),
      RemoteSessionApi.getActor(state),
      FeatureFlagsApi.getFlag(state, FLAGS.STATE_RESTORE),
    ]);
    // Only partners applications are saved in the server
    const isPartner = partnerId && token;

    // On remote appointments, PARTNER will keep the state saved in the
    // server. This should enable future restore from application on remote.
    // This just ignore SUPPORTING_PARTNER calls (it would pass the above
    // conditions)
    const isRemoteSupporting =
      actor && actor.type === ActorType.SUPPORTING_PARTNER;

    // Only allow server save for pages before "About You"
    const isPathAllowed = filters.isPathSaveEnabled(window.location.pathname);

    // Exit on any of the following criteria
    if (
      !applicationId ||
      !isPartner ||
      !isPathAllowed ||
      !isRestoreEnabled ||
      isRemoteSupporting
    ) {
      return;
    }

    yield call(
      service.saveState,
      partnerId,
      applicationId,
      {
        state: filters.stateToServer(payload),
        path: window.location.pathname,
        routing: getRouting(),
      },
      token
    );
    yield put(actions.serverSave.success());
  } catch (e) {
    yield put(actions.serverSave.failure({ error: e.message }));
  }
}
