import Immutable from 'immutable';
import * as types from './types';
import { tariffStatus } from 'redux/modules/Mobile/constants';
import { newMobileProduct } from 'redux/modules/Mobile/reducer';

const initialState = Immutable.fromJS({
  tariffs: {
    status: null,
    nodes: [],
  },
  selectedTariffLabel: null,
  selectedProducts: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TARIFFS_SUCCESS:
      return state.mergeDeep({
        tariffs: {
          status: tariffStatus.SUCCESS,
          nodes: action.tariffs,
        },
      });
    case types.SET_SELECTED_TARIFF_LABEL: {
      return state.set('selectedTariffLabel', action.label);
    }
    case types.ADD_MOBILE_PRODUCT: {
      const selectedProducts = state.get('selectedProducts').toJS();
      return state.merge({
        selectedProducts: [...selectedProducts, newMobileProduct()],
      });
    }
    case types.RESET_STORE: {
      return initialState;
    }
    default:
      return state;
  }
};
