import Immutable from 'immutable';
import * as types from './types';

const initialState = Immutable.fromJS({
  isPreselectJourney: false,
  mobile: {},
  broadband: {},
  energy: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PRESELECT_PARAMETERS:
      return state.mergeDeep({
        isPreselectJourney: true,
        ...action.parameters,
      });
    case types.RESET_PRESELECT_PARAMETERS:
      return initialState;
    default:
      return state;
  }
};
