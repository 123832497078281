import { STORE_NAME } from './constants';
import { STORE_NAME as PRESELECT_STORE_NAME } from '../constants';
import { FTTP_TARIFFS } from './constants';

export const getTariffs = (state) => {
  return state.getIn([PRESELECT_STORE_NAME, STORE_NAME, 'tariffs']).toJS();
};

export const getSelectedTariff = (state) => {
  return state
    .getIn([PRESELECT_STORE_NAME, STORE_NAME, 'selectedTariff'])
    .toJS();
};

export const getSelectedTariffId = (state) => {
  return state.getIn([
    PRESELECT_STORE_NAME,
    STORE_NAME,
    'selectedTariff',
    'meta',
    'id',
  ]);
};

export const getSelectedRouter = (state) => {
  return state.getIn([PRESELECT_STORE_NAME, STORE_NAME, 'selectedRouterName']);
};

export const hasFttpTariffs = (state) => {
  return getTariffs(state)
    .map((tariff) => tariff.meta.id)
    .some((tariffId) => FTTP_TARIFFS.includes(tariffId));
};

export const getStore = (state) => {
  return state.getIn([PRESELECT_STORE_NAME, STORE_NAME]).toJS();
};

export const getHomephoneOptions = (state) => {
  return state
    .getIn([PRESELECT_STORE_NAME, STORE_NAME, 'homephoneOptions'])
    .toJS();
};

export const getSelectedEeroDevices = (state) => {
  return state.getIn([PRESELECT_STORE_NAME, STORE_NAME, 'selectedEeroDevices']);
};
