import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Profile from 'components/modules/Shared/PageHeading/Profile';
import { PartnerLoginApi } from 'redux/modules/PartnerLogin/api';
import { logout } from 'redux/modules/PartnerLogin/actions';
import { ApplicationApi } from 'redux/modules/App/api';
import { RemoteSessionApi } from 'redux/modules/RemoteSession/v2/api';

export default connect(
  (state) => ({
    displayName:
      PartnerLoginApi.getDisplayName(state) ||
      ApplicationApi.getSalesId(state) ||
      ApplicationApi.getBrandPartnerAgentId(state),
    supportingPartnerId: PartnerLoginApi.getSupportingId(state),
    isPartnerSession: PartnerLoginApi.isPartnerLoggedIn(state),
    isRemoteSession: RemoteSessionApi.isHandshakeComplete(state),
  }),
  (dispatch) => bindActionCreators({ logout }, dispatch)
)(Profile);
