import { STORE_NAME as PRESELECT_STORE_NAME } from 'redux/modules/Preselect/constants';
import { STORE_NAME } from './constants';

export const getStore = (state) => {
  return state.getIn([PRESELECT_STORE_NAME, STORE_NAME])?.toJS();
};

export const getQuoteIds = (state) => {
  return state
    .getIn([PRESELECT_STORE_NAME, STORE_NAME, 'quote', 'current', 'quoteIds'])
    ?.toJS();
};
