export const STORE_NAME = 'Parameters';

export const MOBILE_TARIFF = 'mobile';
export const MOBILE_SIMS = 'mobile_sims';

export const ENERGY_TARIFF = 'energy';
export const ENERGY_TARIFF_TYPE = 'energy_tariff';

export const BROADBAND_PREFERRED_TARIFF = 'bb';
export const BROADBAND_FIBRE_TARIFF = 'bb_fibre_tariff';
export const BROADBAND_COPPER_TARIFF = 'bb_copper_tariff';
export const BROADBAND_SOGEA_TARIFF = 'bb_sogea_tariff';
export const BROADBAND_ROUTER = 'bb_router';
export const BROADBAND_CALL_PACKAGE = 'bb_call_package';
export const BROADBAND_EEROS = 'bb_eeros';

export const MOBILE_PREFIX = 'mobile';
export const BROADBAND_PREFIX = 'bb';
export const ENERGY_PREFIX = 'energy';
