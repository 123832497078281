import Immutable from 'immutable';
import * as types from './types';

const initialState = Immutable.fromJS({
  form: {
    fields: {
      energy: null,
    },
  },
  quote: {
    current: {},
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_STORE: {
      return initialState;
    }
    case types.GET_QUOTES_REQUEST_SUCCESS: {
      return state.mergeDeep({
        quote: {
          current: action.quotes,
          currentTab: action.tariffType,
        },
        form: { fields: { energy: action.quoteType } },
      });
    }
    default:
      return state;
  }
};
