import {
  TARIFF_FTTP_100,
  TARIFF_FTTP_300,
  TARIFF_FTTP_500,
  TARIFF_FTTP_900,
} from '../../Broadband/constants';

export const STORE_NAME = 'Broadband';

export const FTTP_TARIFFS = [
  TARIFF_FTTP_100,
  TARIFF_FTTP_300,
  TARIFF_FTTP_500,
  TARIFF_FTTP_900,
];
export const UW_HUB = 'UW Wi-Fi Hub';

export const OFF_PEAK_SAVER = 'Off-peak Saver';
export const PEAK_SAVER = 'Peak Saver';
