import * as types from './types';

export const storePreselectParameters = () => ({
  type: types.STORE_PRESELECT_PARAMETERS,
});

export const setPreselectParameters = (parameters) => ({
  type: types.SET_PRESELECT_PARAMETERS,
  parameters,
});

export const resetPreselectParameters = () => ({
  type: types.RESET_PRESELECT_PARAMETERS,
});
