export const STORE_NAME = 'Persistence';
export const DEBOUNCE_TIME = 3000;
export const TTL = 1000 * 60 * 60; // 60 minutes

export const SAVED_CHANGES = 'SAVED_CHANGES';
export const UNSAVED_CHANGES = 'UNSAVED_CHANGES';

export const SERVER_SAVE_REQUEST = 'SERVER_SAVE_REQUEST';
export const SERVER_SAVE_SUCCESS = 'SERVER_SAVE_SUCCESS';
export const SERVER_SAVE_FAILURE = 'SERVER_SAVE_FAILURE';

export const SERVER_LOAD_REQUEST = 'SERVER_LOAD_REQUEST';
export const SERVER_LOAD_SUCCESS = 'SERVER_LOAD_SUCCESS';
export const SERVER_LOAD_FAILURE = 'SERVER_LOAD_FAILURE';
